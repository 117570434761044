export const testimonialsData = [
    {
        id: 1,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'Bhaiya, this is a very excellent course. Concepts were thoroughly explained and made simple. These videos cleared my concepts regarding DSA topics. Thank you for making this friendly course.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/153197660_cda7.jpg',
        name: 'Stuti Kumari.',
        country: 'India',
    },
    {
        id: 2,
        starRating: '⭐⭐⭐⭐',
        comment: 'so far so good, except I have some doubts about some of the runtimes he is coming up with but I suppose he is the professional and not me, lol. Authentically Prateek is a professional Instructor and knows how things work, and his English is very pellucid and understandable',
        userImage: 'https://img-c.udemycdn.com/user/50x50/157421960_6370.jpg',
        name: 'Ryan Z.',
        country: 'United States',
    },
    {
        id: 3,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'I struggled with graph and dp . This course has definetly levelled up my graph and dp skills as the concepts taught were made very clear. Looking forward to buy graph theory for CP . Lastly, prateek sir is a brilliant instructor and an ideal inspiration as well. Thanks for this amazing course!',
        userImage: 'https://img-c.udemycdn.com/user/50x50/147043884_ce17.jpg',
        name: 'Rohit R.',
        country: 'India',
    },
    {
        id: 4,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'Very decent course. Concept and coding videos are interactive and easy to understand by Prateek bhaiya. Exercise questions are challenging and interesting to do. Thank you entire team of coding minutes for making this course. Looking forward to more such amazing courses.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/114793402_6de2_4.jpg',
        name: 'Aditya Raj S.',
        country: 'India',
    },
    {
        id: 5,
        starRating: '⭐⭐⭐⭐',
        comment: 'I think this is perfect course for those who know the basics and wants to be a very good problem solver. Everything taught is really Amazing and in easy way....I can definitely recommend this course to you all who are planning to crack placements. Go for it definitely it is worth',
        userImage: 'https://img-c.udemycdn.com/user/50x50/55821688_de92_2.jpg',
        name: 'Bhargav J.',
        country: 'India',
    },
    {
        id: 6,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'If you want to ace data-structures and algorithms, definitely you should take this course. I searched for this type course when i got started with CP,i am unable to solve the problems, but this course made me to get better in CP.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/128285716_3a2c.jpg',
        name: 'Barma V.',
        country: 'India',
    },
    {
        id: 7,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'The is the best course I have ever seen. Game theory Algorithms in Competitive programming really very helpful for every type of student. I recomend you to go through it. It really helps you to improve your CP knowledge.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/130132288_712e_2.jpg',
        name: 'Kuntal O.',
        country: 'India',
    },
    {
        id: 8,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'Bhaiya, this is a very excellent course. Concepts were thoroughly explained and made simple. These videos cleared my concepts regarding DSA topics. Thank you for making this friendly course.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/153197660_cda7.jpg',
        name: 'Stuti Kumari.',
        country: 'India',
    },
    {
        id: 9,
        starRating: '⭐⭐⭐⭐',
        comment: 'so far so good, except I have some doubts about some of the runtimes he is coming up with but I suppose he is the professional and not me, lol. Authentically Prateek is a professional Instructor and knows how things work, and his English is very pellucid and understandable',
        userImage: 'https://img-c.udemycdn.com/user/50x50/157421960_6370.jpg',
        name: 'Ryan Z.',
        country: 'United States',
    },
    {
        id: 10,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'I struggled with graph and dp . This course has definetly levelled up my graph and dp skills as the concepts taught were made very clear. Looking forward to buy graph theory for CP . Lastly, prateek sir is a brilliant instructor and an ideal inspiration as well. Thanks for this amazing course!',
        userImage: 'https://img-c.udemycdn.com/user/50x50/147043884_ce17.jpg',
        name: 'Rohit R.',
        country: 'India',
    },
    {
        id: 11,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'Very decent course. Concept and coding videos are interactive and easy to understand by Prateek bhaiya. Exercise questions are challenging and interesting to do. Thank you entire team of coding minutes for making this course. Looking forward to more such amazing courses.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/114793402_6de2_4.jpg',
        name: 'Aditya Raj S.',
        country: 'India',
    },
    {
        id: 12,
        starRating: '⭐⭐⭐⭐',
        comment: 'I think this is perfect course for those who know the basics and wants to be a very good problem solver. Everything taught is really Amazing and in easy way....I can definitely recommend this course to you all who are planning to crack placements. Go for it definitely it is worth',
        userImage: 'https://img-c.udemycdn.com/user/50x50/55821688_de92_2.jpg',
        name: 'Bhargav J.',
        country: 'India',
    },
    {
        id: 13,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'If you want to ace data-structures and algorithms, definitely you should take this course. I searched for this type course when i got started with CP,i am unable to solve the problems, but this course made me to get better in CP.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/128285716_3a2c.jpg',
        name: 'Barma V.',
        country: 'India',
    },
    {
        id: 14,
        starRating: '⭐⭐⭐⭐⭐',
        comment: 'The is the best course I have ever seen. Game theory Algorithms in Competitive programming really very helpful for every type of student. I recomend you to go through it. It really helps you to improve your CP knowledge.',
        userImage: 'https://img-c.udemycdn.com/user/50x50/130132288_712e_2.jpg',
        name: 'Kuntal O.',
        country: 'India',
    },
]